import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./css/loader.scss";
import reportWebVitals from "./reportWebVitals";
import Loader from "./component/Loader";

const App = React.lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Loader/>}>
    <App />
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
